import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData, CaseStudyProps } from '@/mock/SiteData';
import { CopyCentered } from '@/components/shared/copy-centered';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { TitleCopy } from '@/components/shared/title-copy';
import { Review } from '@/components/shared/review';
import { ImageGallery } from '@/components/shared/image-gallery';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { LeftRightCopy } from '@/components/shared/left-right-copy';
import { cn } from '@/lib/utils';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import ReactLogo from '@/assets/images/technologies/react.inline.svg';
import NodeJsLogo from '@/assets/images/technologies/nodejs.inline.svg';
import TypescriptLogo from '@/assets/images/technologies/typescript.inline.svg';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import useStickyScrollAnimation from '@/hooks/useStickyScrollAnimation';
import { SectionHeader } from '@/components/shared/section-header';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import { FullScreenSlider } from '@/components/shared/full-screen-slider';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy: CaseStudyProps | undefined = CaseStudyData.find((m) => m.id === 'projectx');

const ProjectX = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook

  const copyCenteredRef = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const { elementRef: leftRightCopyFirst, titleRef: leftRightCopyFirstTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopySecond, titleRef: leftRightCopySecondTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopyThird, titleRef: leftRightCopyThirdTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopyFourth, titleRef: leftRightCopyFourthTitle } =
    useStickyScrollAnimation();

  const sectionHeaderRef = useScrollAnimation();

  const fullScreenSliderRef = useScrollAnimation();

  const lightSeparatorFirst = useScrollAnimation();

  const lightSeparatorSecond = useScrollAnimation();

  const lightSeparatorThird = useScrollAnimation();

  const darkSeparatorFirst = useScrollAnimation();

  const darkSeparatorSecond = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="project-white-section">
          <div className="flex flex-col gap-20 bg-white py-20 lg:gap-36 lg:py-36">
            <TitleCopy
              ref={titleCopyFirst}
              title="The Client"
              description="You know how some projects are so transformative, so groundbreaking, that they're kept under wraps? Well, this is one of those. We can't tell you who our client is, but let's just say they're a leader in an industry where billions of dollars are at stake, and their move toward becoming fully data-driven is nothing short of revolutionary."
              className={ANIMATED_CLASSNAME}
            />
            <Separator
              ref={lightSeparatorFirst}
              className={cn('mx-auto w-[95%] max-w-7xl', ANIMATED_CLASSNAME)}
            />
            <CopyCentered
              ref={copyCenteredRef}
              tag="GOAL"
              copy="Our client's goal was to become fully data-driven within 5 years by building a comprehensive technology ecosystem. "
              description="They aimed to integrate GenAI and automation tools to turn vast data into actionable insights, positioning themselves as industry leaders—all while staying efficient with a lean, streamlined team."
              className={ANIMATED_CLASSNAME}
            />
            <Separator
              ref={lightSeparatorSecond}
              className={cn('mx-auto w-[95%] max-w-7xl', ANIMATED_CLASSNAME)}
            />
            <TitleCopy
              ref={titleCopySecond}
              title="The Challenge"
              description="Our mystery client, known internally as Mr. X, had a bold goal: to transform their entire operation into a fully automated, data-driven powerhouse. But here's the twist—they wanted to do it without expanding their team, relying on cutting-edge tech and top-tier partners (like us!) to get the job done."
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGalleryFirst}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[
                { src: '/images/case-studies/projectx/challenge-1.webp', alt: 'challenge1' },
                { src: '/images/case-studies/projectx/challenge-2.webp', alt: 'challenge2' },
              ]}
            />
            <SectionHeader
              title={'The Process'}
              ref={sectionHeaderRef}
              description="At Loop3, we thrive on creating solutions that push the boundaries of technology—efficiently and discreetly. Whether it's building new tools or seamlessly integrating existing platforms like Slack for team communication, Affinity for managing key relationships through a powerful CRM, or Pitchbook for market intelligence, our mission is always the same: streamline everything. For Mr. X, we ensured our tech blended into their daily operations like a perfectly placed operative, adapting to their workflow without disruption. Below is a glimpse into our process, from covert brainstorming to stealthy iterations, ensuring each solution delivers maximum value while fitting seamlessly into their highly classified ecosystem."
              className={cn('mx-auto px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            />
            <LeftRightCopy
              ref={leftRightCopyFirst}
              titleRef={leftRightCopyFirstTitle}
              contentSide="left"
              title="Ideation"
              copy="Our process begins when Mr. X approaches us with a challenge, a big idea, or a covert task aimed at improving their day-to-day operations. Whether it's solving a hidden pain point or optimizing an existing workflow, their requests are always focused on making things run smoother, faster, and smarter. Once we fully understand the mission, we gather our best minds for a secret brainstorming session, exploring advanced tech like GenAI, automation, or custom development to design the most effective solution. The goal? To craft a tool that aligns perfectly with Mr. X's vision for a more data-driven future—without leaving a trace.
"
              images={[{ src: '/images/case-studies/projectx/ideation.webp', alt: 'image1' }]}
            />
            <LeftRightCopy
              ref={leftRightCopySecond}
              titleRef={leftRightCopySecondTitle}
              title="Crafting a Solution"
              copy="Once the plan is in place, we waste no time in building an MVP (Minimum Viable Product) designed to directly address the challenge. In missions like these, speed is critical, so we focus on delivering a functional, tactical solution that can be tested and refined in real-time. By getting this prototype into the hands of Mr. X quickly, we're able to gather early feedback, make adjustments, and ensure the solution is heading in the right direction—efficient, effective, and always on target."
              images={[
                { src: '/images/case-studies/projectx/solution-1.webp', alt: 'solution1' },
                { src: '/images/case-studies/projectx/solution-2.webp', alt: 'solution2' },
                { src: '/images/case-studies/projectx/solution-3.webp', alt: 'solution3' },
              ]}
            />
            <LeftRightCopy
              ref={leftRightCopyThird}
              titleRef={leftRightCopyThirdTitle}
              contentSide="left"
              title="Iteration"
              copy="With feedback from the MVP in hand, we enter the next phase: iteration. Each version of the product is improved and enhanced, ensuring it delivers more value with every tweak. As we refine the solution, we expand its capabilities and fine-tune its performance, making sure it aligns perfectly with Mr. X's evolving needs and top-secret objectives. Our goal is to ensure the solution not only meets today's requirements but stays ahead of the curve for whatever challenges come next."
              images={[
                { src: '/images/case-studies/projectx/iteration-1.webp', alt: 'iteration1' },
                { src: '/images/case-studies/projectx/iteration-2.webp', alt: 'iteration2' },
                { src: '/images/case-studies/projectx/iteration-3.webp', alt: 'iteration3' },
              ]}
            />
            <LeftRightCopy
              ref={leftRightCopyFourth}
              titleRef={leftRightCopyFourthTitle}
              title="Repeat"
              copy="As we refine and scale each product, we're also working on new, cutting-edge solutions behind the scenes. Our team handles multiple projects simultaneously, ensuring that while we improve and maintain existing tools, we're always developing fresh ones to meet Mr. X's ever-evolving needs. This approach allows us to continuously expand their technology ecosystem, creating a seamless flow of innovation and improvement, without skipping a beat."
            />
            <Separator
              ref={lightSeparatorThird}
              className={cn('mx-auto max-w-7xl w-[95%] lg:-mb-36', ANIMATED_CLASSNAME)}
            />
          </div>
        </div>
        <div className="animate-transition flex flex-col gap-36 bg-loop-black lg:py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              { icon: <ReactLogo key="react" />, id: 'react' },
              { icon: <NodeJsLogo key="nodejs" />, id: 'nodejs' },
              { icon: <TypescriptLogo key="typescript" />, id: 'typescript' },
            ]}
            description="For Mr. X's project, we chose a tech stack that emphasized modularity, performance, and adaptability to meet their diverse use cases. React powered a responsive and intuitive frontend, while Spring Boot and WebFlux ensured a high-performing, scalable backend. By leveraging AWS and a serverless architecture using SST, we delivered a solution that offered both flexibility and reliability. This architecture allowed us to manage independent services seamlessly, providing Mr. X with a tailored, future-proof system that supports their evolving operational needs."
            className={{
              section: ANIMATED_CLASSNAME,
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              tag="the result"
              className={{
                section: cn('mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: '<w>/<w> Thanks to our custom-built solutions and seamless integrations, <w>Mr. X<w> has significantly enhanced their operational efficiency. <w>Automation and AI tools<w> now drive their decision-making, allowing their lean team to work smarter and more strategically. Quietly leading in innovation, Mr. X is well on their way to becoming a <w>data-driven powerhouse<w>, setting the pace in their industry—without revealing their secrets.',
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
          </div>
          <FullScreenSlider
            ref={fullScreenSliderRef}
            className={cn('mx-auto px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            tag="the product"
            images={[
              { src: '/images/case-studies/projectx/album-1.webp', alt: 'album1' },
              { src: '/images/case-studies/projectx/album-2.webp', alt: 'album2' },
              { src: '/images/case-studies/projectx/album-4.webp', alt: 'album4' },
            ]}
          />
          <Separator
            ref={darkSeparatorFirst}
            className={cn('mx-auto max-w-7xl w-[95%] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
          <Review
            client={{ name: 'Mr X.', role: 'Head of Technology' }}
            message="“Loop3 has become an expert in every detail about our tools, internal stakeholders, technical ecosystem and product vision to become an integral part of my team that doesn't feel like an outside agency.”"
            logo={null}
          />
          <Separator
            ref={darkSeparatorSecond}
            className={cn('mx-auto max-w-7xl w-[95%] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default ProjectX;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO title="Loop3 Studio - Project X" description={`${caseStudy.hero.heroDescriptionText}`} />
    );
  }
};
